/*
 * Duplicated in the Inertia front-end
 */
import {computed, Ref} from '@vue/composition-api';

export interface LatexFragment {
  type: 'text' | 'display' | 'inline';
  content: string;
}

const displayPattern = /(\$\$[^$]+?\$\$)/;
const inlinePattern = /(\$[^$]+?\$)/;

export function useLatexFragments(raw: Ref<string | null>) {
  const fragments = computed<LatexFragment[]>(() => {
    let rawValue = raw.value;

    if (!rawValue) {
      return [];
    }

    // We replace all escaped dollar signs so that we can avoid matching them as part of
    // finding LaTeX fragments in the string.
    rawValue = rawValue.replaceAll('\\$', '<dollar>');

    const result: LatexFragment[] = [];

    // We try to split by the display pattern (i.e. double dollar signs) first, since the inline pattern would also match
    const splits = rawValue.split(displayPattern);
    for (const split of splits) {
      if (displayPattern.test(split)) {
        result.push({type: 'display', content: split.slice(2, -2)});
        continue;
      }

      // Once we've excluded display fragments, we can search for inline LaTeX fragments (i.e. single dollar signs)
      const inlineSplits = split.split(inlinePattern);
      for (const inlineSplit of inlineSplits) {
        if (inlinePattern.test(inlineSplit)) {
          result.push({
            type: 'inline',
            content: inlineSplit.slice(1, -1),
          });
          continue;
        }

        // Anything that isn't LaTeX is considered to be plain text
        result.push({type: 'text', content: inlineSplit});
      }
    }

    return (
      result
        // Filter any empty strings
        .filter((item) => item.content)

        // Replace the escaped dollar signs that were found earlier
        .map(({type, content}) => ({
          type,
          content: content.replaceAll('<dollar>', '\\$'),
        }))
    );
  });

  return {
    fragments,
  };
}
