<template>
  <div>
    <small
      v-if="showHelpfulHint && helpfulHintPosition === 'above'"
      v-html="$t('calculationInput.helpfulTip')"
    />
    <v-text-field
      v-format-number-only="{preventNonNumerical: preventNonNumerical}"
      :solo="solo"
      :flat="flat"
      :outlined="outlined"
      :hint="notationValue"
      :persistent-hint="notationValue !== ''"
      :error-messages="invalidMessage"
      :background-color="color"
      hide-details="auto"
      v-bind="$attrs"
      :value="value"
      v-on="$listeners"
      @input="updateInvalidMessage"
    >
      <template v-if="prependText !== null" v-slot:prepend-inner>
        <slot name="prependText" :prependText="prependText">
          <stemble-latex :content="prependText" />
        </slot>
      </template>
      <template v-slot:message>
        <div v-if="!invalidMessage" class="notation-container" v-html="notationValue" />
        <span v-else>
          {{ invalidMessage }}
        </span>
      </template>
      <template v-if="appendText !== null" v-slot:append>
        <slot name="appendText" :append-text="appendText">
          <stemble-latex :content="appendText" />
        </slot>
      </template>
    </v-text-field>
    <small
      v-if="showHelpfulHint && helpfulHintPosition === 'below'"
      v-html="$t('calculationInput.helpfulTip')"
    />
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import StembleLatex from '@/tasks/components/StembleLatex';
import DOMPurify from 'dompurify';

export default {
  name: 'CalculationInput',
  components: {StembleLatex},
  directives: {
    formatNumberOnly: {
      bind(el, binding) {
        const handler = function (e) {
          if (e.target.value) {
            let result = e.target.value;
            if (binding.value.preventNonNumerical) {
              result = result.replace(/[^eE+\-\d.]/g, '');
              result = result.replace(/^[eE]/g, '');
              result = result.replace(/([-+]){2,}/g, '$1');
              result = result.replace(/(\.){2,}/g, '$1');
              result = result.replace(/(\d+)[-+](\d+|$)/g, '$1$2');
              result = result.replace(/\.([eE])/g, '$1');

              const splits = result.split(/[eE]/g);
              for (let i = 0; i < splits.length; i++) {
                splits[i] = splits[i].replace(/^0{2,}(?!\.)/, '0');
                splits[i] = splits[i].replace(/^([-+])?0(?=\d+)/, '$1');
                splits[i] = splits[i].replace(/^([-+])?\./, '$10.');
                splits[i] = splits[i].replace(/^(.*?\..*?)\./, '$1');
              }
              e.target.value = splits.length === 1 ? splits[0] : splits[0] + 'e' + splits[1];
            } else {
              e.target.value = result;
            }

            e.target.dispatchEvent(new CustomEvent('input'));
          }
        };
        el.addEventListener('input', handler);
      },
    },
  },
  inheritAttrs: false,
  props: {
    solo: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'white',
    },
    flat: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: null,
    },
    prependText: {
      type: String,
      default: null,
    },
    appendText: {
      type: String,
      default: null,
    },
    showHelpfulHint: {
      type: Boolean,
      default: false,
    },
    helpfulHintPosition: {
      type: String,
      default: 'below',
      validator(value) {
        return value === 'above' || value === 'below';
      },
    },
    preventNonNumerical: {
      type: Boolean,
      default: true,
    },
    showScientificNotation: {
      type: Boolean,
      default: true,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
  },
  data() {
    return {
      invalidMessage: null,
    };
  },
  computed: {
    notationValue() {
      if (!this.showScientificNotation || !this.value) {
        return '';
      }

      const value = this.value.toString();
      const indexOfE = value.indexOf('e');

      if (indexOfE === -1) {
        return '';
      }

      const number = value.substring(0, indexOfE);
      const exponent = value.length > indexOfE ? value.substring(indexOfE + 1) : '';

      return DOMPurify.sanitize(`Scientific Notation: ${number} x 10 <sup>${exponent}</sup>`);
    },
    isValid() {
      const validExpression = /^[-+]?\d+(\.\d*)?([eE][-+]?\d+(\.\d*)?)?$/;

      return !this.value || (validExpression.test(this.value) && !this.outOfRange());
    },
  },
  watch: {
    value() {
      if (this.isValid) {
        this.invalidMessage = null;
      }
    },
  },
  methods: {
    outOfRange() {
      return +this.value < this.min || +this.value > this.max;
    },
    updateInvalidMessage: debounce(function () {
      if (this.isValid) {
        return;
      }

      console.log(this.outOfRange());

      if (/[eE]$/.test(this.value)) {
        this.invalidMessage = this.$t('formValidation.calculationInput.trailingE');
      } else if (this.outOfRange()) {
        this.invalidMessage = this.$t('formValidation.calculationInput.outOfRange');
      } else {
        this.invalidMessage = this.$t('formValidation.calculationInput.invalidInput');
      }
    }, 1000),
  },
};
</script>
<style scoped>
.notation-container {
  margin-top: 8px;
  padding-left: 0;
}
</style>
