<template>
  <div v-if="isLoaded">
    <runtime-template
      v-bind="$attrs"
      :task="task"
      :task-state="taskState"
      :template="taskContent"
      v-on="$listeners"
    />
  </div>
  <div v-else>Loading content...</div>
</template>

<script>
import RuntimeTemplate from '@/tasks/components/RuntimeTemplate';

export default {
  name: 'ContentArea',
  components: {
    RuntimeTemplate,
  },
  inheritAttrs: false,
  props: {
    task: {},
    taskState: {},
  },
  computed: {
    isLoaded() {
      return this.task.taskStates.length === 0 || this.taskState !== null;
    },
    taskContent() {
      return `<div>${this.task.taskContent}</div>`;
    },
  },
};
</script>
