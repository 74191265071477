<template>
  <div class="video-container">
    <youtube
      v-if="videoType.toLowerCase() === 'youtube'"
      ref="youtube"
      class="video-width"
      :video-id="youtubeVideoId"
      :player-vars="mergedYoutubePlayerVars"
      :nocookie="true"
      v-bind="$attrs"
      @ready="onReady"
      v-on="$listeners"
    />
    <span v-else>Unsupported video type "{{ videoType }}"</span>
  </div>
</template>

<script>
import {Youtube} from 'vue-youtube';
import mergeObjects from 'lodash.merge';

//see https://developers.google.com/youtube/player_parameters?playerVersion=HTML5
const DEFAULT_YOUTUBE_PLAYER_VARS = {
  modestBranding: 1, // I don't want a lot of YouTube branding on the thing
  rel: 0, // I'd rather not load related videos at all (distracting to students), but that's not possible
  autoplay: 0, // Don't want the video to start playing before it is shown, nor to annoy users.
  controls: 1, // can turn this off if we want to
};

export default {
  name: 'StembleVideo',
  components: {
    Youtube,
  },
  inheritAttrs: false,
  props: {
    state: Boolean,
    youtubeVideoId: {
      type: String,
      required: true,
    },
    youtubePlayerVars: {
      type: Object,
      default: () => ({}),
    },
    videoType: {
      type: String,
      default: 'youtube', //youtube is currently the only supported type
    },
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
    mergedYoutubePlayerVars() {
      return mergeObjects({}, DEFAULT_YOUTUBE_PLAYER_VARS, this.youtubePlayerVars);
    },
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    stopVideo() {
      this.player.stopVideo();
    },
    onReady() {
      this.registerErrorHandler();
    },
    registerErrorHandler() {
      this.player.on('error', (err) => this.$emit('rawError', err));
    },
  },
};
</script>

<style scoped>
.video-container {
  text-align: center;
}
.video-container >>> .video-width {
  max-width: 100%;
  display: inline-block;
}
</style>
