<template>
  <div v-if="!inline">
    <v-runtime-template :template="template" v-bind="$attrs" v-on="$listeners" />
  </div>
  <span v-else>
    <v-runtime-template :template="template" v-bind="$attrs" v-on="$listeners" />
  </span>
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template';

export default {
  name: 'RuntimeTemplate',
  components: {
    VRuntimeTemplate,
  },
  inheritAttrs: false,
  props: {
    template: {},
    inline: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
