<template>
  <div style="width: 100%">
    <!-- Task Content -->
    <content-area v-if="task.taskContent" class="mb-4" :task-state="taskState" :task="newTask" />
    <stemble-video
      v-if="renderVideo"
      ref="player"
      :youtube-video-id="task.youtubeVideoId"
      @ready="onReady"
      @ended="onEnded"
      @playing="onPlaying"
      @paused="onPaused"
      @buffering="onBuffering"
      @rawError="onRawError"
    />
    <!--
              FIXME: Need better communication with the backend.  Reports to user if api fails, etc.
              -->
    <div v-if="isCorrect">
      <span class="green--text">
        {{ $t('assignmentFeedback.videoTasks.complete') }}
      </span>
      <p class="text-muted text-sm">
        {{ $t('assignmentFeedback.videoTasks.thanks') }}
      </p>
    </div>
    <div v-else>
      <p v-if="progressMessage">
        {{ progressMessage }}
      </p>
    </div>
    <v-progress-linear v-if="isCorrect" :value="100" :max="100" color="green" />
    <v-progress-linear v-else :value="progressValue" :active="started" />
  </div>
</template>

<script>
import ContentArea from '@/tasks/components/ContentArea';
import {TaskCardMixin} from '../mixins/task-card';
import StembleVideo from '@/tasks/components/StembleVideo';
import Task from '@/tasks/models/Task';
import {isDevelopment} from '@/environment';

export default {
  name: 'WatchVideoTask',
  components: {
    StembleVideo,
    ContentArea,
  },
  mixins: [TaskCardMixin()],
  props: {
    task: {
      type: Task,
      required: true,
    },
  },
  data() {
    return {
      interval: null,
      started: false,
      ended: false,
      playing: false,
      submitted: false,
      duration: null,
      secondsWatched: 0,
      renderVideo: true,
    };
  },
  computed: {
    newTask() {
      //Created to hard-code some language until a suitable replacement can be implemented
      let watchVideoLanguage = this.$t('assignmentFeedback.videoTasks.watchToComplete');
      let oldTask = this.task;
      oldTask.taskContent = watchVideoLanguage;
      return oldTask;
    },
    player() {
      return this.$refs.player.player;
    },
    durationFraction() {
      // This line will allow us more flexibility in the future
      //return this.task.content.durationFraction || 0.9;
      return isDevelopment() ? 0.1 : 0.8;
    },
    requiredDuration() {
      return this.duration ? this.duration * this.durationFraction : 24 * 60 * 60;
    },
    endFraction() {
      return 0.98;
    },
    endPosition() {
      return this.duration ? this.duration * this.endFraction : 24 * 60 * 60;
    },
    hasWatchedEnough() {
      return this.requiredDuration !== null && this.secondsWatched >= this.requiredDuration;
    },
    requirementsMet() {
      return this.hasWatchedEnough && this.ended;
    },
    progressValue() {
      return (this.secondsWatched / this.requiredDuration) * 100;
    },
    progressMessage() {
      if (this.started && this.ended && !this.hasWatchedEnough) {
        return this.$t('assignmentFeedback.videoTasks.skipped');
      } else if (this.started && !this.hasWatchedEnough) {
        return this.$t('assignmentFeedback.videoTasks.watchMore');
      } else if (this.started && !this.ended) {
        return this.$t('assignmentFeedback.videoTasks.watchAll');
      } else if (this.isSubmitting) {
        return this.$t('assignmentFeedback.videoTasks.saving');
      }
      return null;
    },
  },
  created() {
    this.initialize();
  },
  activated() {
    this.initialize();
    this.forceVideoRerender();
  },
  deactivated() {
    this.clear();
  },
  destroyed() {
    this.clear();
  },
  methods: {
    initialize() {
      this.clear();
      this.interval = setInterval(() => {
        if (this.playing) {
          this.secondsWatched++;
        }

        if (this.requirementsMet && !this.submitted && !this.isCorrect) {
          this.submitted = true;
          this.submitResponse();
          this.clear();
        }
      }, 1000);
    },
    clear() {
      this.playing = false;
      if (this.interval !== null) {
        clearInterval(this.interval);
      }
      this.interval = null;
    },
    forceVideoRerender() {
      this.renderVideo = false;
      this.$nextTick().then(() => {
        this.renderVideo = true;
      });
    },
    submitResponse() {
      let response = {
        watched: true,
      };
      this.$emit('submit-response', {response});
    },
    onBuffering() {
      this.playing = false;
      this.checkPositionForEnded();
    },
    onPlaying() {
      this.playing = true;
      this.started = true;
    },
    onPaused() {
      this.playing = false;
      this.checkPositionForEnded();
    },
    onEnded() {
      this.playing = false;
      this.ended = true;
    },
    onReady() {
      this.player.getDuration().then((duration) => {
        this.duration = duration;
      });
    },
    onRawError(err) {
      this.$errorReporting.showErrorDialog(err).catch((err) =>
        this.$errorReporting.catchError(err, {
          youtubeErrorCode: err.data,
          interval: this.interval,
          started: this.started,
          ended: this.ended,
          playing: this.playing,
          submitted: this.submitted,
          duration: this.duration,
          secondsWatched: this.secondsWatched,
          renderVideo: this.renderVideo,
        })
      );
    },
    checkPositionForEnded() {
      this.player.getCurrentTime().then((position) => {
        if (position >= this.endPosition) {
          this.ended = true;
        }
      });
    },
  },
};
</script>
