<template>
  <div>
    <component
      :is="questionComponent"
      v-if="task.content.component"
      ref="question"
      v-bind="$attrs"
      :task="task"
      :task-state="taskState"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  name: 'DynamicQuestion',
  inheritAttrs: false,
  props: ['task', 'taskState'],
  computed: {
    isLoaded() {
      return this.task.taskStates.length === 0 || this.taskState !== null;
    },
    questionComponent() {
      const {component, questionId} = this.task.content;

      return () => import(`./dynamic-questions/${component || questionId}.vue`);
    },
  },
  methods: {
    submitResponse() {
      this.$refs.question.submitResponse();
    },
    setResponse(...args) {
      if (this.$refs.question) {
        this.$refs.question.setResponse(...args);
      } else {
        // FIXME: Hack
        setTimeout(() => {
          this.setResponse(...args);
        }, 500);
      }
    },
    clearResponse(...args) {
      if (this.$refs.question) {
        this.$refs.question.clearResponse(...args);
      } else {
        // FIXME: Hack
        setTimeout(() => {
          this.clearResponse(...args);
        }, 500);
      }
    },
  },
};
</script>
