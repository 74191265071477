<template>
  <div style="width: 100%">
    <!-- Task Content -->
    <content-area :task-state="taskState" :task="task" />

    <v-divider />
    <v-form @submit.prevent="submitResponse()">
      <v-row align="stretch" align-items="start">
        <v-col sm="2">
          <span>Your answer:</span>
        </v-col>
        <v-col sm="10">
          <v-radio-group v-model="attempt.choice" :disabled="!allowEditing">
            <v-radio
              v-for="option in options"
              :key="option.value"
              :label="option.text"
              :value="option.value"
            />
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ContentArea from '@/tasks/components/ContentArea';
import swal from 'sweetalert2';
import {TaskCardMixin} from '../mixins/task-card';
import {nextTick} from '@vue/composition-api';
import {temporarySettingResponseFlag} from '@/tasks/utils/temporarySettingResponseFlag';

export default {
  name: 'MultipleChoiceQuestion',
  mixins: [TaskCardMixin()],
  components: {
    ContentArea,
  },
  data() {
    return {
      isSettingResponse: false,
      previousResponse: false,
      attempt: {
        choice: null,
      },
    };
  },
  computed: {
    responseState() {
      return this.isNotSameAnswer ? null : this.isCorrect;
    },
    options() {
      return this.task.content.options.map((option, i) => {
        return {
          text: option,
          value: i,
        };
      });
    },
    user() {
      return this.$auth.user;
    },
    isAnswerSelected() {
      return this.attempt.choice !== null;
    },
    isTryAgain() {
      return this.previousResponse !== null;
    },
    isNotSameAnswer() {
      return this.isTryAgain && this.previousResponse !== this.attempt.choice;
    },
  },
  watch: {
    attempt: {
      deep: true,
      handler() {
        if (this.isSettingResponse) {
          return;
        }
        this.$emit('update:inputData', this.attempt);
      },
    },
  },
  methods: {
    submitResponse() {
      if (this.attempt.choice !== null) {
        let response = {
          choice: this.attempt.choice,
        };
        this.$emit('submit-response', {response});
      } else {
        swal({
          text: 'You must select an option.',
          type: 'error',
        });
      }
    },
    setResponse(response) {
      temporarySettingResponseFlag(
        (v) => (this.isSettingResponse = v),
        () => {
          this.attempt.choice = response.content.choice;
          this.previousResponse = response.content.choice;
        }
      );
    },
    clearResponse() {
      temporarySettingResponseFlag(
        (v) => (this.isSettingResponse = v),
        () => {
          this.attempt.choice = null;
          this.previousResponse = null;
        }
      );
    },
  },
};
</script>
