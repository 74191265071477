<template>
  <span>
    <template v-for="(fragment, i) in fragments">
      <katex-element
        v-if="fragment.type === 'inline' || fragment.type === 'display'"
        :key="i"
        :display-mode="fragment.type === 'display'"
        :expression="fragment.content"
        :style="responsive ? 'font-size: 1em' : 'font-size: 13px'"
      />
      <!-- This needs to be on one line for formatting reasons -->
      <template v-else>{{ fragment.content }}</template>
    </template>
  </span>
</template>

<script>
import KatexElement from '@/tasks/components/KatexElement';
import {useLatexFragments} from '@/tasks/composables/useLatexFragments';
import {computed, toRef} from '@vue/composition-api';
import {formatLatexNumberValue} from '@/tasks/composables/formatLatexNumberValue';
import {substituteMustache} from '@/common/utils/substituteMustache';

export default {
  name: 'StembleLatex',
  components: {
    KatexElement,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    responsive: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    taskState: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const {fragments} = useLatexFragments(toRef(props, 'content'));

    const stringTaskState = computed(() => {
      const {taskState} = props;
      if (!taskState) {
        return {};
      }

      const entries = taskState.symbols.map((name) => {
        const value = taskState.getValueByName(name);

        return [name, value?.content ? formatLatexNumberValue(value.content) : ''];
      });

      return Object.fromEntries(entries);
    });

    const substituteTaskState = (content) => substituteMustache(content, stringTaskState.value);

    const substitutedFragments = computed(() =>
      fragments.value.map((fragment) => ({
        ...fragment,
        content: substituteTaskState(fragment.content),
      }))
    );

    return {fragments: substitutedFragments};
  },
};
</script>
