/**
 * Mixin for all answer-blocks for setting the response when it changes
 */
import Grade from '@/grades/models/Grade';

export function TaskCardMixin() {
  return {
    methods: {
      setResponse() {
        // This method should set a previous response
      },
      clearResponse() {
        // This method should clear a response
      },
    },
    created() {
      if (this.response) {
        this.setResponse(this.response);
      }
    },
    computed: {
      isCorrect() {
        return this.grade ? this.grade.isCorrect() : null;
      },
      taskContent() {
        return this.task.taskContent || null;
      },
    },
    props: {
      assignment: {
        type: Object,
        required: false,
        default: null,
      },
      task: {
        type: Object,
        required: true,
      },
      taskState: {
        type: Object,
        required: false,
        default: null,
      },
      response: Object,
      grade: Grade,
      isSubmitting: {
        type: Boolean,
        default: false,
      },
      allowEditing: {
        type: Boolean,
        default: true,
      },
      selectedResponseIndex: Number,
      responses: Array,
    },
  };
}

export default TaskCardMixin;
