



























import {computed, defineComponent, PropType, Ref, ref, watch} from '@vue/composition-api';
import {Task} from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import {TaskResponseType} from '@/tasks/types/TaskResponseType';
import TaskResponse from '@/tasks/models/TaskResponse';
import TaskUiComponent from '@/tasks/components/task-ui-components/TaskUiComponent.vue';
import {RawTaskState} from '@/task-states/types/RawTaskState';

export default defineComponent({
  name: 'DataDrivenTask',
  components: {TaskUiComponent},
  inheritAttrs: false,
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DataDrivenTask, TaskResponseType.DataDrivenResponse>>,
      required: true,
    },
    taskState: {
      type: Object as PropType<RawTaskState | null>,
      default: null,
    },
    response: {
      type: Object as PropType<TaskResponse<TaskResponseType.DataDrivenResponse> | null>,
      default: null,
    },
  },
  setup(props, {emit}) {
    const responseData = ref<Record<string, any> | null>(props.response?.content?.data ?? null);

    const uiComponentConfigs = computed(() => props.task.content!.uiConfigs);

    /*
     * Response Data
     */
    let clonedResponseData: Ref<Record<string, any>> = ref({});
    let clonedAttachments: Ref<Record<string, File[]>> = ref({});

    const cloneTaskValueMap = () => {
      let cleanedResponseData = responseData.value ?? {};

      // Empty submissions can sometimes result in an empty array being passed to/from the server.
      // In these cases, we need to fix this map to be an object or the user's changes will not be applied properly
      if (Array.isArray(cleanedResponseData) && cleanedResponseData.length === 0) {
        cleanedResponseData = {};
      }

      // Finally, clone the response data.
      clonedResponseData.value = JSON.parse(JSON.stringify(cleanedResponseData));
    };

    watch(responseData, cloneTaskValueMap, {immediate: true});

    /*
     * Submission data
     */
    const submitResponse = () => {
      emit('submit-response', {response: {data: clonedResponseData.value}});
    };

    /*
     * Legacy Task methods
     */
    const setResponse = (response: TaskResponse<TaskResponseType.DataDrivenResponse>) => {
      responseData.value = response.content?.data ?? null;
    };

    const clearResponse = (...args: any[]) => {
      //
    };

    const mergeAttachmentsIntoSingleArray = () => {
      return Object.values(clonedAttachments.value).reduce((acc, val) => acc.concat(val), []);
    };

    const emitAllData = () => {
      emit('update:taskAttachments', mergeAttachmentsIntoSingleArray());
      emit('update:inputData', {data: clonedResponseData.value});
    };

    return {
      uiComponentConfigs,
      submitResponse,
      setResponse,
      clearResponse,
      clonedResponseData,
      clonedAttachments,
      responseData,
      emitAllData,
    };
  },
});
