import {nextTick} from '@vue/composition-api';

export const temporarySettingResponseFlag = (
  flagSetter: (value: boolean) => void,
  cbk: () => void
) => {
  flagSetter(true);

  try {
    cbk();
  } finally {
    nextTick(() => flagSetter(false));
  }
};
